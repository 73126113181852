.height-100vh {
	height: 100vh;
}


/* -------------- buttons shadow--------- */
.button-box-shadow {
	-webkit-box-shadow: 0 1px 1px rgba(72, 78, 85, .6);
	box-shadow: 0 1px 1px rgba(72, 78, 85, .6);
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-ms-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
}

.button-box-shadow:hover {
	-webkit-box-shadow: 0 10px 20px rgba(72, 78, 85, .6);
	box-shadow: 0 10px 20px rgba(72, 78, 85, .6);
	-webkit-transform: translateY(-2px);
	-moz-transform: translateY(-2px);
	-ms-transform: translateY(-2px);
	-o-transform: translateY(-2px);
	transform: translateY(-2px);
}

.btn-secondary {
	background-color: darkorange !important;
	border: none !important;
}

.show-modal {
	display: block !important;
}

.modalWrapper {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.6);
	flex-wrap: wrap;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-body{
	max-height:400px;
	overflow-y: auto;
}

/* Table header text*/
/* .table-header{
	color: #fff;
	font-weight: 600;
	
} */

.selectColumn {
	width: 70px;
}

.gridText {
	font-size: 12px;
}

@media only screen and (min-width: 1024px) {

	.tableContainer {
		/* max-height: 370px;
		overflow-y: scroll; */
		font-size: 13px;
		color: #000;
	}

	th {
		position: sticky;
		top: 0;
		background: #4e73df;
		box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
	}

}

/*no-more-tables table media query*/

@media only screen and (max-width: 650px) {

	/* Force table to not be like tables anymore */
	#no-more-tables table,
	#no-more-tables thead,
	#no-more-tables tbody,
	#no-more-tables th,
	#no-more-tables td,
	#no-more-tables tr {
		display: block; border: 1px solid #000;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	#no-more-tables thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	#no-more-tables tr {
		border: 1px solid #000;
	}

	#no-more-tables td {
		/* Behave  like a "row" */
		border: 1px solid #000!important;
		border-bottom: 1px solid #000;
		position: relative;
		padding-left: 50%;
		white-space: normal;
		text-align: left;
	}

	#no-more-tables td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 5px;
		white-space: nowrap;
		text-align: left;
		font-weight: bold;
	}
	
	#no-more-tables td:before {
		content: attr(data-title);
	}

}

/*==========================CSS 17-12-2024========================*/
.mt-6{ margin-top: 60px;}
h5{ color: #fff;}
.topmenuBar{ padding: 2px; margin:0px 5px;}
.topbar.navbar-light .navbar-nav .nav-item .nav-link{ color: #fff!important;}
.dropdown-menu{background-color:#4e73df!important;}
.dropdown-item{ color: #fff!important; padding:0px 15px!important;}
.dropdown-item:hover{ color: #ddd!important; background-color:#4e73df!important;}
.dropdown-toggle::after{ content: "\25be"!important;}
/*footer { position: fixed; bottom: 0; width: 100vw;}*/
.copyright{ color: #000;}
.table-bordered td{ border: 1px solid #999!important; font-size: 12px;}
.table-bordered th{ border: 1px solid #999!important; background-color: #4e73df; font-size: 14px; color: #fff;}

/*====================================START CSS 2nd JAN 2025 =====================================*/

/* .height150{height: 150px!important;} */

.nav-tabs {
	background-color: #f8f9fa; /* Tab container background color */
	border-radius: 0.5rem; /* Rounded corners */
	padding: 0.5rem; /* Add padding */
  }
  
  .nav-tabs .nav-link {
	color: #495057; /* Default tab text color */
	margin-right: 0.5rem; /* Space between tabs */
	border: none; /* Remove default border */
	border-radius: 0.5rem; /* Rounded corners for tabs */
	transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .nav-tabs .nav-link.active {
	background-color: #007bff; /* Active tab background color */
	color: white; /* Active tab text color */
	font-weight: bold; /* Make active tab text bold */	
	border-top: 3px solid #4e73df!important
  }
  
  .nav-tabs .nav-link:hover {
	background-color: #e2e6ea; /* Hover background color */
	color: #007bff; /* Hover text color */
  }
  
  .tab-content {
	background-color: #ffffff; /* Tab content background color */	
	border: 1px solid #dee2e6; /* Border around tab content */
	border-radius: 0.5rem; /* Rounded corners */
	padding: 1rem; /* Add padding */
	margin-top: -1px; /* Prevent border overlap with tabs */
  }


  /*--------------------------------------------------------*/
  .table td{ padding: 4px!important; font-size: 13px;}

  .table th{ padding: 12px 5px!important; font-size: 13px;}
